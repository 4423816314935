import React, { useState } from 'react'
import * as b from './bootstrap.module.css'
import Icon from "../components/icon.tsx"
import Translate from './translate'


const GRID_TYPE = {
  c: 'column',
  r: 'row',
}

const checkGridType = (value) => Object.values(GRID_TYPE).some(el => el === value) ? value : ''

const Grid = ({ children }) => {
  const [grid, setGrid] = useState(GRID_TYPE.c)

  const updateGrid = (value) => {
    if (checkGridType(value)) {
      setGrid(value)
    }
  }


  return (
    <>
      <div className={['grid', b.dNone, b.dLgFlex, b.justifyContentLgEnd, b.alignItemsCenter, b.mb3].join(' ')}>
        <span className={['grid-text', b.mr3].join(' ')}><Translate tKey="Change view" /></span>
        <button onClick={() => updateGrid(GRID_TYPE.r)} className={['settings-button', grid === GRID_TYPE.r ? 'settings-button-active' : '', b.mr2].join(' ')}>
          <Icon  size={25} icon="Columne-list" className={"icon-padding"}/> 
        </button>

        <button onClick={() => updateGrid(GRID_TYPE.c)} className={['settings-button', grid === GRID_TYPE.c ? 'settings-button-active' : ''].join(' ')}>
          <Icon  size={25} icon="Columne-mesh" className={"icon-padding"}/> 
        </button>

      </div>
      {children({ grid })}
    </>
  )
}

export default Grid;