import React, { useState, Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import AllProducts from '../components/all-products';
import * as b from "../components/bootstrap.module.css"
import Grid from '../components/grid'
import ProductInputs from '../components/product-inputs';
import Posts from '../components/posts';
import useMedia from '../hooks/useMedia';
import BlogContent from '../components/blog-content';
import TableOfContents from '../components/table-of-contents';
import Breadcrumbs from "../components/breadcrumbs"
import Layout from "../components/layout";
import RankingShortcode from '../components/shortcodes/ranking-shortcode';
import Translate from '../components/translate';
import RysioProducts from '../../static/assets/Rysiek-stoper.svg'
import RysioProductsMobile from '../../static/assets/Rysiek-stoper-mobile.svg'
import RysioProductsCashcredit from '../../static/assets/Rysiek-cashCredit.svg'
import RysioProductsCard from '../../static/assets/Rysiek-card.svg'
import RysioProductsMortgage from '../../static/assets/Rysiek-mortgage.svg'
import RysioProductsAccount from '../../static/assets/Rysiek-account.svg'
import Rysio4arms from '../../static/assets/Rysiek-4arms.svg'
import CommentsForm from '../components/comments-form';
import Icon from "../components/icon.tsx";
import resolveUrl from '../utils/urls/resolve-url';
import Faq from '../components/faq';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Products = ({
  data: { subCategories, mysqlTerms, allMysqlBlog, mysqlRanking, faq },
  pageContext: { cluster, breadcrumbs, term_normalized, terms_path, ...settings },
  location: { pathname }
}) => {
  const [productSettings, setProductsSettings] = useState(settings)
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isFaq, setIsFaq] = useState(false);
  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);

  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)
  return (
    <Layout pathname={pathname}>
      <div className={b.pbMd4}>
        <section className={['intro-products', b.pt4, b.ptMd5,'introProductsPage'].join(' ')}>
          <div className={[b.container, "breadcrumbs-mobile-products", b.pb3, "scroll-inner"].join(" ")}>
            <Breadcrumbs items={breadcrumbs} textWhite/>
          </div>
          <div className={`${b.container} ${b.pb2} ${b.positionRelative}`}>
            <div className={b.row}>
              <div className={b.colLg12}>
                <h1 className={`big-title ${b.pb3} ${b.w75} ${b.ptLg5} ${b.textWhite}`}>
                  {mysqlTerms.title} <Translate tKey="- comparison engine of the best offers" />
                </h1>

                {!cluster.startsWith('accounts_') && !cluster.startsWith('cards') ?
                  (<div className={`intro-calculator ${b.p3} ${b.pLg4}`}>
                    <ProductInputs
                      settings={productSettings}
                      onChangeInput={setProductsSettings}
                      productName={`${productSettings.types}${productSettings?.subtypes?.[productSettings.types] || ''}`}
                      type={settings.types}
                    />
                  </div>) : ''}
              </div>
            </div>
          </div>
          {cluster.includes('credits') && !cluster.endsWith('1') && (<RysioProductsCashcredit className='products-credits-image'/>)} 
          {cluster === 'credits_1' && (<RysioProductsMortgage className='products-mortgage-image'/>)} 
          {cluster.startsWith('cards') && (<RysioProductsCard className='products-cards-image'/>)} 
          {cluster.startsWith('accounts_') && (<RysioProductsAccount className='products-accounts-image'/>)} 
          {cluster === 'firms_2' ? (<Rysio4arms className='products-image'/>) : cluster.startsWith('firms_') ? !isMobileResolution ? <RysioProducts  className={'products-image'} /> : <RysioProductsMobile className={'products-image'} />: ''} 
        </section>
      </div>

      <div className={`${b.container} ${b.pt3} ${b.ptLg4}`}>
        <Grid>
          {({ grid }) =>
            <AllProducts grid={grid} settings={productSettings} />
          }
        </Grid>

        {(mysqlTerms.description || mysqlTerms.description_additional || mysqlTerms.description_alt) && <div className={`${b.row} product-page blog-content`}>
          <section className={`${b.colXl8} ${b.mb4} blackToGray dark-theme-listing`}>
            {<RankingShortcode value={`${mysqlTerms.description} ${mysqlTerms.description_alt} ${mysqlTerms.description_additional}`} type={mysqlRanking?.url}>
              {(fragments) => fragments?.map(({ slice, ranking }, i) => (<Fragment key={i}>
                {slice && <BlogContent content={slice} />}
                {ranking}
              </Fragment>))}
            </RankingShortcode>}
            {faq?.nodes.length > 0 && (
              <>
                <h2 id="section-title-15">
                  <Translate tKey="Often asked questions" />
                </h2>
                {faq.nodes.map(({ title, text }, i) => (
                  <Faq title={title} text={text} key={i}/>
                ))}
                <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                  <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm();toggleFaq(true); }}>
                    <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                    <Translate tKey="Ask your own question" />
                  </button>
                </div>
              </>
            )}

            <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlTerms.mysqlId} commentType="term" faq={isFaq} />
          </section>

          {<TableOfContents selector=".post-content h2, .post-content h3, .post-content h4" />}
        </div>}
      </div>

      {subCategories.nodes.length > 0 && 
        <div className={[b.container, b.my4, allMysqlBlog.nodes.length === 0 ? `${b.myLg5} ${b.pbLg5}` : b.mtLg5].join(' ')}>
          <h2 className={["title", b.dBlock, b.mb4].join(' ')}><Translate tKey="Topics in category:" /> {mysqlTerms.term}</h2>
          
          <div className={['subcategory-row', b.row].join(' ')}>
            {subCategories.nodes.map(({path,term, term_normalized}, i) => (
              <div className={[b.colMd6, b.colLg4, b.colXl3, b.px1].join(' ')} key={i}>
                <Link to={resolveUrl(path, term_normalized)}>
                  <div className={['category-box',b.mt2,b.dFlex].join(' ')}>
                    <div className='category-bl'/>
                    <div className={b.p3}>{term}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      }

      {allMysqlBlog.nodes.length > 0 && (<>
        <section className={`${b.container} ${b.pt3} ${b.ptLg4} ${b.pb4} ${b.pbLg5} ${b.mbLg5}`}>
          <h2 className={["title", b.dBlock, b.mb4, b.mt3].join(' ')}><Translate tKey="Related content" /> {mysqlTerms.term}</h2>
          <Posts items={allMysqlBlog.nodes} countItem={999} step={4} />
        </section>
      </>)}
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($mysqlId: Int!, $withoutUrls: [String]) {
    allMysqlBlog(filter: {url: {nin: $withoutUrls}, term_id: {eq: $mysqlId}, is_category: {eq: 0}, description: {ne: null}, status: {eq: "publish"}}) {
      nodes {
        ...PostData
      }
    }
    
    subCategories: allMysqlTerms(filter: {mysqlParent: {eq: $mysqlId}, is_article: {ne: 0}, template: {ne: "products"}, menu: {eq: null}}) {
      nodes {
        term
        term_normalized
        path
      }
    }

    mysqlRanking(term_id: { eq: $mysqlId }) {
      url
    }

    mysqlTerms(mysqlId: { eq: $mysqlId }) {
      description_additional
      description_alt
      description
      title
      mysqlId
      term
    }

    faq: allMysqlFaq(
      filter: {type: {eq: "term"}, item: {eq: $mysqlId}, answer: {ne: "null"}}
      sort: {fields: priority, order: DESC}
    ) {
      nodes {
       title: question
       text: answer
      }
    }

    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`;

export default Products;
